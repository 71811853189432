import React from "react";
import { graphql, Link } from "gatsby";
import { Helmet } from "react-helmet";

import Layout from "./layout";
import PageInfo from "./pageinfo";
import { StdDate } from "./date";
import { Tags } from "../components/tags";
import { AllTags } from "../components/all-tags";
// import { TweetButton } from "../components/tweet-button";
import { SidebarWrapper } from "../components/sidebar-wrapper";
// import { getTweetUrl } from "../common/utils";

import "../components/blog.css";

export default function BlogPostTemplate({
    data,
}) {
    const {
        posts: {
            html,
            fields: { slug, redirect },
            frontmatter: {title, author, date, updated, updateReason, tags, relocated, unlisted}
        },
    } = data;
    // const tweetMeUrl = getTweetUrl({url: redirect, hashtags: "thenewtoys", text: "@tjcrowder"});
    return <>
        <PageInfo title={title} />
        <Layout>
            <div className="blog-index-link"><Link to="/blog">&#8592; blog index</Link></div>
            <SidebarWrapper
                main={<>
                        {/*
                        <TweetButton
                            url={redirect}
                            text={`"${title}" on #thenewtoys`}
                        />
                        */}
                        <h1 className="title">{title}</h1>
                        <p className="blog-post-meta">
                            Posted <StdDate v={date}/> by {author}
                            {updated && <>
                                <br />
                                <span>
                                    Updated <StdDate v={updated}/>
                                    {updateReason && (": " + updateReason)}
                                </span>
                                </>
                            }
                            <br />
                            Tags: <Tags tags={tags} />
                        </p>
                        {relocated
                            ?
                                <section className="relocated">
                                    <p>
                                        This post has been moved <a href={relocated}>here</a>. You'll
                                        be redirected to it in a moment.
                                    </p>
                                </section>
                            :
                                <section
                                    dangerouslySetInnerHTML={{ __html: html }}
                                />
                        }
                </>}
                subs={relocated ? null : <>
                    <p>
                        Have a question or comment about this post?
                        {/*
                        <a href={tweetMeUrl} target="_blank">Tweet me</a>!
                        */}
                        {" "}
                        <a className="always-underline" href="https://hachyderm.io/@tjcrowdertech" target="_blank">Ping me on Mastodon at @tjcrowdertech@hachyderm.io!</a>
                    </p>
                </>}
                side={<>
                    <div>Posts by Tag</div>
                    <div>
                        <AllTags />
                    </div>
                </>}
            />
        </Layout>
        {relocated &&
            <Helmet>
                <meta http-equiv="refresh" content={`3; url=${relocated}`} />
            </Helmet>
        }
    </>;
}
export const pageQuery = graphql`
    query($slug: String!) {
        posts: markdownRemark(fields: { slug: { eq: $slug } }) {
            html
            fields {
                slug
                redirect
            }
            frontmatter {
                author
                date(formatString: "YYYY-MM-DDTHH:mm:ssZ")
                updated(formatString: "YYYY-MM-DDTHH:mm:ssZ")
                updateReason
                title
                tags
                relocated
            }
        }
    }
`;